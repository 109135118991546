import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/source.scss';
import './styles/base.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter, createRoutesFromElements, Navigate, Route,
    RouterProvider,
} from "react-router-dom";
import LoadingPage from "@/pages/loading";

const Home = React.lazy(() => import("@/pages/home"));
const NotFound = React.lazy(() => import("@/pages/error/not-found"));
const App = React.lazy(() => import("@/app"));
const Dashboard = React.lazy(() => import("@/dashboard"));
const DashboardHome = React.lazy(() => import("@/pages/dashboard/home"));
const RecipeDashboardHome = React.lazy(() => import("@/pages/cooking/recipe/dashboard"));
const ToolDashboardHome = React.lazy(() => import("@/pages/cooking/tool/dashboard"));
const IngredientDashboardHome = React.lazy(() => import("@/pages/cooking/ingredient/dashboard"));
const CuisineTypeDashboardHome = React.lazy(() => import("@/pages/cooking/cuisineType/dashboard"));
const NoteDashboardHome = React.lazy(() => import("@/pages/note/dashboard"));
const TagDashboardHome = React.lazy(() => import("@/pages/note/tag/dashboard"));
const RecipeHome = React.lazy(() => import("@/pages/cooking/recipe"));
const RecipeDetailPage = React.lazy(() => import("@/pages/cooking/recipe/recipe"));
const RecipeEditPage = React.lazy(() => import("@/pages/cooking/recipe/edit"));
const IngredientHome = React.lazy(() => import("@/pages/cooking/ingredient"));
const IngredientDetailPage = React.lazy(() => import("@/pages/cooking/ingredient/ingredient"));
const ToolHome = React.lazy(() => import("@/pages/cooking/tool"));
const ToolDetailPage = React.lazy(() => import("@/pages/cooking/tool/tool"));
const CuisineTypeHome = React.lazy(() => import("@/pages/cooking/cuisineType"));
const CuisineTypeDetailPage = React.lazy(() => import("@/pages/cooking/cuisineType/cuisineType"));
const UserHome = React.lazy(() => import("@/pages/user/user"));
const SignInPage = React.lazy(() => import("@/pages/user/sign-in"));
const Register = React.lazy(() => import("@/pages/user/register"));
const NodeHome = React.lazy(() => import("@/pages/note"));
const NodeEditPage = React.lazy(() => import("@/pages/note/edit"));
const BrowserFrame = React.lazy(() => import("@/pages/browser-pages/browser"));
const BrowserHome = React.lazy(() => import("@/pages/browser-pages/browser-home"));
const AboutPage = React.lazy(() => import("@/pages/app-pages/about"));
const SearchPage = React.lazy(() => import("@/pages/app-pages/search"));
const ContactPage = React.lazy(() => import("@/pages/app-pages/contact"));
const TransactionPage = React.lazy(() => import("@/pages/app-pages/note/transaction"));

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="">
            <Route path="browser" element={<BrowserFrame/>}>
                <Route path="" element={<BrowserHome/>}/>
            </Route>

            <Route path="dashboard" element={<Dashboard/>}>
                <Route path="" element={<DashboardHome/>}/>
                <Route path="cooking">
                    <Route path="recipe">
                        <Route path="" element={<RecipeDashboardHome/>}/>
                    </Route>
                    <Route path="tool">
                        <Route path="" element={<ToolDashboardHome/>}/>
                    </Route>
                    <Route path="ingredient">
                        <Route path="" element={<IngredientDashboardHome/>}/>
                    </Route>
                    <Route path="cuisineType">
                        <Route path="" element={<CuisineTypeDashboardHome/>}/>
                    </Route>
                </Route>
                <Route path="note">
                    <Route path="note">
                        <Route path="" element={<NoteDashboardHome/>}/>
                    </Route>
                    <Route path="tag">
                        <Route path="" element={<TagDashboardHome/>}/>
                    </Route>
                </Route>
                <Route path='error/not-found' element={<NotFound/>}/>
                <Route path="*" element={<Navigate to="error/not-found"/>}/>
            </Route>

            <Route path="" element={<App/>}>
                <Route path="" element={<Home/>}/>
                <Route path="about" element={<AboutPage/>}/>
                <Route path="search" element={<SearchPage/>}/>
                <Route path="cooking">
                    <Route path="recipe">
                        <Route path="" element={<RecipeHome/>}/>
                        <Route path=":id" element={<RecipeDetailPage/>}/>
                        <Route path=":id/edit" element={<RecipeEditPage/>}/>
                    </Route>
                    <Route path="ingredient">
                        <Route path="" element={<IngredientHome/>}/>
                        <Route path=":id" element={<IngredientDetailPage/>}/>
                    </Route>
                    <Route path="tool">
                        <Route path="" element={<ToolHome/>}/>
                        <Route path=":id" element={<ToolDetailPage/>}/>
                    </Route>
                    <Route path="cuisineType">
                        <Route path="" element={<CuisineTypeHome/>}/>
                        <Route path=":id" element={<CuisineTypeDetailPage/>}/>
                    </Route>
                </Route>
                <Route path="user">
                    <Route path="" element={<UserHome/>}/>
                    <Route path="sign-in" element={<SignInPage/>}/>
                    <Route path="register" element={<Register/>}/>
                </Route>
                <Route path="note">
                    <Route path="" element={<NodeHome/>}/>
                    <Route path="edit/*" element={<NodeEditPage/>}/>
                    <Route path="*" element={<NodeHome/>}/>
                </Route>
                <Route path="contact">
                    <Route path="" element={<ContactPage/>}/>
                </Route>
                <Route path="transaction">
                    <Route path="" element={<TransactionPage/>}/>
                </Route>
                <Route path='error/not-found' element={<NotFound/>}/>
                <Route path="*" element={<Navigate to="/error/not-found"/>}/>
            </Route>
        </Route>
    )
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <React.Suspense fallback={<LoadingPage />}>
            <RouterProvider router={router}/>
        </React.Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
